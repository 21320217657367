import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setModalWindowStatus } from '../../../redux/technical/technical-slice';
import i18n from 'i18next';
import EclinicCitasWidget from '../EclinicCitasWidget/EclinicCitasWidget';
import { useTranslation } from 'react-i18next';
import { TfiClose } from 'react-icons/tfi';

import Button from '../Button';
import s from './ModalWindow.module.scss';
import './ModalWindow.css';

const ModalWindow = () => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [basePath, setBasePath] = useState(`/${i18n.language}`);
  const [isAcceptPolicy, setIsAcceptPolicy] = useState(false);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const handleOutsideClick = e => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        dispatch(setModalWindowStatus(false));
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setBasePath(`/${i18n.language}`);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [basePath]);

  const closeModal = () => {
    dispatch(setModalWindowStatus(false));
  };

  const acceptPrivicy = () => {
    if (accepted) {
      setIsAcceptPolicy(true);
    }
  };

  return (
    <div className={s.window} ref={modalRef}>
      <div className={s.window__border}>
        <button className={s.window__closeButton} onClick={closeModal}>
          <TfiClose color="#017683" size={15} />
        </button>
        {!isAcceptPolicy && (
          <div className={s.window__warning}>
            <p className={s.window__text}>
              {t(
                'Before booking an appointment with the doctor and sending your personal data, please confirm that you have read and accepted our Privacy Policy:'
              )}
            </p>
            <div className={s.window__wrapper}>
              <input
                type="radio"
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
                style={{ marginRight: '10px', display: 'inline' }}
              />
              <p
                className={s.window__linkWrapper}
                style={{ margin: '0', display: 'inline' }}
              >
                <span
                  className={s.window__text}
                  style={{ margin: '0', display: 'inline' }}
                >
                  {t('I accept the')}
                </span>
                <Link
                  className={s.window__link}
                  style={{ marginLeft: '5px', display: 'inline' }}
                  to={`${basePath}/privacy-policy`}
                >
                  {t('Privacy Policy')}
                </Link>
              </p>
            </div>
            <div className={s.window__btnWrapper}>
              <Button
                text={t('Booking')}
                btnClass="btnLightMini"
                handleClick={acceptPrivicy}
              />
            </div>
            <div className={s.window__textWrapper}>
              <p className={s.window__accentText}>
                {t('Data Controller:')}{' '}
                <span className={s.window__text}>
                  {t('ART STUDIO DENTAL ASD, S.L.')}
                </span>
              </p>
              <p className={s.window__accentText}>
                {t('Purposes of Processing:')}{' '}
                <span className={s.window__text}>
                  {t(
                    'We will use your data to schedule a visit and address your questions, queries, and suggestions.'
                  )}
                </span>
              </p>
              <p className={s.window__accentText}>
                {t('Rights of Data Subjects:')}{' '}
                <span className={s.window__text}>
                  {t(
                    'You may exercise your rights of access, rectification, deletion, and portability of your data, and the limitation or opposition to its processing at artstudiodental1@gmail.com'
                  )}
                </span>
              </p>
              <p className={s.window__accentText}>
                {t('More Information:')}{' '}
                <span className={s.window__text}>
                  {t('available in our Privacy Policy.')}
                </span>
              </p>
            </div>
          </div>
        )}
        {isAcceptPolicy && (
          <div className={s.window__widget}>
            <EclinicCitasWidget />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalWindow;
