// import { NavLink, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import i18n from 'i18next';
// import s from './Navigation.module.scss';

// const Navigation = () => {
//   const location = useLocation();
//   const { t } = useTranslation();
//   const newLanguage = i18n.language;

//   const getClassName = ({ isActive }) => {
//     return isActive ? `${s.link} ${s.active}` : s.link;
//   };

//   const basePath = `/${newLanguage}`;

//   return (
//     <nav className={s.navigation}>
//       <NavLink
//         className={getClassName({
//           isActive: location.pathname === `${basePath}/`,
//         })}
//         to={`${basePath}/`}
//         title="Go to the home page"
//       >
//         <span>{t('Home')}</span>
//       </NavLink>
//       <NavLink
//         className={getClassName({
//           isActive: location.pathname === `${basePath}/services`,
//         })}
//         to={`${basePath}/services`}
//         title="Go to the services page"
//       >
//         <span>{t('Services')}</span>
//       </NavLink>
//       <NavLink
//         className={getClassName({
//           isActive: location.pathname === `${basePath}/prices`,
//         })}
//         to={`${basePath}/prices`}
//         title="Go to the prices page"
//       >
//         <span>{t('Prices')}</span>
//       </NavLink>
//       <NavLink
//         className={getClassName({
//           isActive: location.pathname === `${basePath}/about`,
//         })}
//         to={`${basePath}/about`}
//         title="Go to the about page"
//       >
//         <span>{t('About us')}</span>
//       </NavLink>
//       <NavLink
//         className={getClassName({
//           isActive: location.pathname === `${basePath}/contacts`,
//         })}
//         to={`${basePath}/contacts`}
//         title="Go to the contacts page"
//       >
//         <span>{t('Contacts')}</span>
//       </NavLink>
//     </nav>
//   );
// };

// export default Navigation;

import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import s from './Navigation.module.scss';

const Navigation = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [basePath, setBasePath] = useState(`/${i18n.language}`);
  const [locationPath, setLocationPath] = useState('/');

  const getClassName = ({ isActive }) => {
    return isActive ? `${s.link} ${s.active}` : s.link;
  };

  useEffect(() => {
    const currentPathname = location.pathname;
    if (currentPathname.startsWith('/en')) {
      setLocationPath(currentPathname.replace(/^\/en/, ''));
    } else if (currentPathname.startsWith('/es')) {
      setLocationPath(currentPathname.replace(/^\/es/, ''));
    } else if (currentPathname.startsWith('/ru')) {
      setLocationPath(currentPathname.replace(/^\/ru/, ''));
    } else {
      setLocationPath('/');
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setBasePath(`/${i18n.language}`);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [basePath]);

  return (
    <nav className={s.navigation}>
      <NavLink
        className={getClassName({
          isActive: locationPath === '/' || locationPath === '',
        })}
        to={`${basePath}/`}
        title="Go to the home page"
      >
        <span>{t('Home')}</span>
      </NavLink>
      <NavLink
        className={getClassName({
          isActive: locationPath === '/services',
        })}
        to={`${basePath}/services`}
        title="Go to the services page"
      >
        <span>{t('Services')}</span>
      </NavLink>
      <NavLink
        className={getClassName({
          isActive: locationPath === '/prices',
        })}
        to={`${basePath}/prices`}
        title="Go to the prices page"
      >
        <span>{t('Prices')}</span>
      </NavLink>
      <NavLink
        className={getClassName({
          isActive: locationPath === '/about',
        })}
        to={`${basePath}/about`}
        title="Go to the about page"
      >
        <span>{t('About us')}</span>
      </NavLink>
      <NavLink
        className={getClassName({
          isActive: locationPath === '/contacts',
        })}
        to={`${basePath}/contacts`}
        title="Go to the contacts page"
      >
        <span>{t('Contacts')}</span>
      </NavLink>
    </nav>
  );
};

export default Navigation;
