import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Navigation from '../Navigation';
import LanguageSelect from 'components/Shared/LanguageSelect';
import menu from '../../../images/menu.webp';
import close from '../../../images/close.png';

import s from './BottomPanel.module.scss';
import style from '../Header.module.scss';

const BottomPanel = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const pathname = location.pathname;
  const language = pathname.substring(1, 3) || 'en';

  useEffect(() => {
    setCurrentLanguage(language);
  }, [language]);

  useEffect(() => {
    const closeMenu = setTimeout(() => {
      setIsMenuOpen(false);
    }, 10000);
    const scrollListener = () => {
      if (window.scrollY > 300) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('scroll', scrollListener);
    return () => {
      clearTimeout(closeMenu);
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const openMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={s.bottomPanel}>
      <div className="container">
        <div className={s.bottomPanel__content}>
          {isMobile && (
            <button className={s.bottomPanel__menu} onClick={openMenu}>
              {!isMenuOpen && (
                <img
                  src={menu}
                  alt="Menu botton"
                  className={s.bottomPanel__menuIcon}
                />
              )}
              {isMenuOpen && (
                <img
                  src={close}
                  alt="Close menu botton"
                  className={s.bottomPanel__menuIcon}
                  style={{ width: 25, height: 25 }}
                />
              )}
            </button>
          )}
          {isMenuOpen && isMobile && (
            <div className={s.bottomPanel__menuNavigation}>
              <div className={s.bottomPanel__menuContainer}>
                <Navigation />
                <div className={style.header__part}>
                  <div className={style.header__partClinic}>
                    <span className={style.header__title}>
                      {t('Dental Clinic')}
                    </span>
                    <span className={style.header__text}>
                      {t('Mon-Sun: 9:00 — 21:00')}
                    </span>
                  </div>
                  <a className={style.header__link} href="tel:+34614258852">
                    <span className={style.header__title}>+34 614 258 852</span>
                  </a>
                </div>
              </div>
            </div>
          )}
          {!isMobile && <Navigation />}
          <LanguageSelect
            currentLanguage={currentLanguage}
            changeLanguage={changeLanguage}
          />
        </div>
      </div>
    </div>
  );
};

export default BottomPanel;
