import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCookiesPolicy } from '../../../redux/technical/technical-selectors';
import { setCookiesPolicy } from '../../../redux/technical/technical-slice';

import s from './SwitchBtn.module.scss';

const SwitchBtn = ({ type }) => {
  const { googleScript, eclinicCitasWidget, saveSettings } =
    useSelector(getCookiesPolicy);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (!isUpdated) {
      const newSettings = {
        googleScript: true,
        eclinicCitasWidget: true,
        saveSettings: true,
      };
      dispatch(setCookiesPolicy(newSettings));
      setIsUpdated(true);
    } else {
      return;
    }
  }, [dispatch, isUpdated]);

  const handleClick = () => {
    let newSettings = {};
    if (type === 'google') {
      newSettings = {
        googleScript: !googleScript,
        eclinicCitasWidget,
        saveSettings,
      };
      setIsActive(!googleScript);
    }
    if (type === 'eclinic') {
      newSettings = {
        googleScript,
        eclinicCitasWidget: !eclinicCitasWidget,
        saveSettings,
      };
      setIsActive(!eclinicCitasWidget);
    }
    if (type === 'data') {
      newSettings = {
        googleScript,
        eclinicCitasWidget,
        saveSettings: !saveSettings,
      };
      setIsActive(!saveSettings);
    }
    dispatch(setCookiesPolicy(newSettings));
  };

  const handleClickText = () => {
    let newSettings = {};
    if (type === 'google') {
      newSettings = {
        googleScript: false,
        eclinicCitasWidget,
        saveSettings,
      };
      setIsActive(false);
    }
    if (type === 'eclinic') {
      newSettings = {
        googleScript,
        eclinicCitasWidget: false,
        saveSettings,
      };
      setIsActive(false);
    }
    if (type === 'data') {
      newSettings = {
        googleScript,
        eclinicCitasWidget,
        saveSettings: false,
      };
      setIsActive(false);
    }
    dispatch(setCookiesPolicy(newSettings));
  };

  return (
    <div className={s.switchBtn}>
      <div
        className={s.switchBtn__container}
        style={{ justifyContent: isActive ? 'left' : 'right' }}
        onClick={handleClick}
      >
        <div className={s.switchBtn__toggle}></div>
      </div>
      <span className={s.switchBtn__text} onClick={handleClickText}>
        {t('Disabled')}
      </span>
    </div>
  );
};

export default SwitchBtn;
