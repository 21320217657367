export const getModalWindowStatus = ({ technical }) =>
  technical.modalWindowStatus;
export const getTechnicalError = ({ technical }) => technical.error;
export const getTechnicalMessage = ({ technical }) => technical.message;
export const getPolicyWindowVisibility = ({ technical }) =>
  technical.policyWindowVisibility;
export const getCookiesPolicy = ({ technical }) => technical.cookiesPolicy;
export const getSettigsWindowVisibility = ({ technical }) =>
  technical.settigsWindowVisibility;
export const getLanguage = ({ technical }) => technical.language;
