import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { setModalWindowStatus } from '../../redux/technical/technical-slice';
import { getCookiesPolicy } from '../../redux/technical/technical-selectors';
import Logo from 'components/Shared/Logo';
import TopPanel from './TopPanel';
import BottomPanel from './BottomPanel';
import Button from 'components/Shared/Button';
import s from './Header.module.scss';

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const cookiesPolicy = useSelector(getCookiesPolicy);
  const [isEclinicCitasWidget, setIsEclinicCitasWidget] = useState(
    cookiesPolicy.eclinicCitasWidget
  );

  useEffect(() => {
    setIsEclinicCitasWidget(cookiesPolicy.eclinicCitasWidget);
  }, [cookiesPolicy]);

  const openModal = () => {
    dispatch(setModalWindowStatus(true));
  };

  return (
    <header className={s.header}>
      <TopPanel />
      <div className="container">
        <div className={s.header__content}>
          <Logo width={150} height={90} />
          {!isMobile && (
            <div className={s.header__part}>
              <div className={s.header__partClinic}>
                <span className={s.header__title}>{t('Dental Clinic')}</span>
                <span className={s.header__text}>
                  {t('Mon-Sun: 9:00 — 21:00')}
                </span>
              </div>
              <div className={s.header__linkWrapper}>
                <a className={s.header__link} href="tel:+34614258852">
                  <span className={s.header__title}>+34 614 258 852</span>
                </a>
                <a className={s.header__link} href="tel:+34872266185">
                  <span className={s.header__title}>+34 872 266 185</span>
                </a>
              </div>
            </div>
          )}
          {isEclinicCitasWidget && (
            <Button
              text="Make an appoitment"
              btnClass="btnLight"
              handleClick={openModal}
            />
          )}
          {!isEclinicCitasWidget && (
            <a
              href="https://wa.me/+34614258852"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button text="Get a consultation" btnClass="btnLight" />
            </a>
          )}
        </div>
      </div>
      <BottomPanel />
    </header>
  );
};

export default Header;
