import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import {
  setPolicyWindowVisibility,
  setCookiesPolicy,
  setSettigsWindowVisibility,
} from '../../../redux/technical/technical-slice';
import { getSettigsWindowVisibility } from '../../../redux/technical/technical-selectors';
import Button from 'components/Shared/Button';

import s from './MainCookieWindow.module.scss';

const MainCookieWindow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [basePath, setBasePath] = useState(`/${i18n.language}`);
  const settigsWindowVisibility = useSelector(getSettigsWindowVisibility);

  useEffect(() => {
    const handleLanguageChange = () => {
      setBasePath(`/${i18n.language}`);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [basePath]);

  const acceptCookies = () => {
    dispatch(setPolicyWindowVisibility(false));
    dispatch(
      setCookiesPolicy({
        googleScript: true,
        eclinicCitasWidget: true,
        saveSettings: true,
      })
    );
  };

  const declineCookies = () => {
    dispatch(setPolicyWindowVisibility(false));
  };

  const handleClick = () => {
    if (!settigsWindowVisibility) {
      dispatch(setSettigsWindowVisibility(true));
    } else {
      dispatch(setSettigsWindowVisibility(false));
    }
  };

  return (
    <div className={s.policyWindow}>
      <div className={s.policyWindow__textPartWrapper}>
        <div className={s.policyWindow__textPart}>
          <p className={s.cookies__text}>
            {t(
              'We use cookies to enhance your website experience, analyze traffic, and personalize content. By accepting, you consent to their use. You can adjust cookie'
            )}
          </p>
          <button
            onClick={handleClick}
            style={{ color: 'var(--background-btn-second-color)' }}
          >
            {t('settings')}
          </button>
          <p className={s.cookies__text}>{t('or learn more in our')}</p>
          <Link
            className={s.cookies__text}
            style={{
              fontWeight: 'bold',
              color: 'var(--btn-text-color)',
              padding: '0px 5px',
            }}
            to={`${basePath}/cookies`}
          >
            <span
              className={s.cookies__textPolicy}
              style={{
                fontWeight: 'bold',
                color: 'var(--background-btn-second-color)',
              }}
            >
              {t('Cookie Policy.')}
            </span>
          </Link>
          <p className={s.cookies__text}>
            {t(
              'Rejecting cookies may limit certain functionalities of the website.'
            )}
          </p>
        </div>
      </div>
      <div className={s.policyWindow__btnPart}>
        <Button
          text={t('Allow All Cookies')}
          btnClass="btnLightMini"
          handleClick={acceptCookies}
        />
        <Button
          text={t('Reject Cookies')}
          btnClass="btnLightMini"
          handleClick={declineCookies}
        />
      </div>
    </div>
  );
};

export default MainCookieWindow;
