import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaOrg = () => {
  const schema = `{
    "@context": "https://schema.org",
    "@type": "Dentist",
    "url": "https://asdental.org/",
    "image": "https://asdental.org/asd.jpg",
    "name": "Art Studio Dental",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Calle Bruselas, 10, isla IV, 43-C",
      "addressLocality": "Platja d'Aro",
      "addressRegion": "Girona",
      "postalCode": "17250",
      "addressCountry": "ES"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 41.816659,
      "longitude": 3.060862
    },
    "openingHours": "Mo-Su 09:00-21:00",
    "telephone": "+34614258852",
    "sameAs": "https://maps.app.goo.gl/wkkWBiUXFk5Z2jat7",
    "priceRange": "€€€"
  }`;

  return (
    <Helmet>
      <script type="application/ld+json">{schema}</script>
    </Helmet>
  );
};

export default SchemaOrg;
