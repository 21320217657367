import { useEffect, useRef, memo } from 'react';
import { useMediaQuery } from 'react-responsive';

import s from './EclinicCitasWidget.module.scss';

function EclinicCitasWidget() {
  const container = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const loadScript = src =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.setAttribute('id', 'eclinic-citas-widget-script');
      script.setAttribute('domain', 'https://www.dasieclinic.com');
      script.setAttribute(
        'title',
        'ca39f66d5091355a6e82ef6e574ac5af81d3ac704d8cdec25ce261607f991406'
      );
      script.setAttribute('data-toggle', 'tooltip');
      script.async = true;
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      container.current.appendChild(script);
    });

  useEffect(() => {
    const loadScripts = async () => {
      try {
        await loadScript(
          'https://www.dasieclinic.com/public/widget/cites/js/api_v1_0.js'
        );
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };

    loadScripts();
  }, []);

  return (
    <div className={s.widget}>
      <div
        ref={container}
        style={{ width: isMobile ? '250px' : '100%', overflowX: 'auto' }}
      ></div>
    </div>
  );
}

export default memo(EclinicCitasWidget);
