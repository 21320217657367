import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { SiGooglemaps } from 'react-icons/si';
import { MdLocalPhone } from 'react-icons/md';
import { SlHome } from 'react-icons/sl';
import { BsWhatsapp } from 'react-icons/bs';

import s from './TopPanel.module.scss';

const TopPanel = () => {
  const [isVisible, setIsVisible] = useState(true);
  const isDesctop = useMediaQuery({ minWidth: 1280 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [basePath, setBasePath] = useState(`/${i18n.language}`);

  useEffect(() => {
    const handleLanguageChange = () => {
      setBasePath(`/${i18n.language}`);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [basePath]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <div className={s.topPanel}>
        <div className={s.topPanel__part}>
          <SlHome color="white" size={isDesctop ? 17 : 15} />
          <span className={s.topPanel__text}>Art Studio Dental</span>
          {isDesctop && (
            <div className={s.topPanel__text}>
              <address>
                Calle Bruselas, 10, isla IV, 43-C, Playa D'Aro, Girona, 17250,
                Spain
              </address>
            </div>
          )}
        </div>
        <a className={s.topPanel__part} href="tel:+34614258852">
          <MdLocalPhone color="white" size={isDesctop ? 17 : 15} />
          <span className={s.topPanel__text}>+34 614 258 852</span>
        </a>
        {!isMobile && (
          <a
            className={s.topPanel__part}
            href="https://wa.me/+34614258852"
            target="_blank"
            rel="noreferrer noopener"
          >
            <BsWhatsapp color="white" size={isDesctop ? 17 : 15} />
            <span className={s.topPanel__text}>WhatsApp</span>
          </a>
        )}
        {!isMobile && (
          <Link className={s.topPanel__part} to={`${basePath}/contacts`}>
            <SiGooglemaps color="white" size={isDesctop ? 17 : 15} />
            <span className={s.topPanel__text}>Google Map</span>
          </Link>
        )}
      </div>
    )
  );
};

export default TopPanel;
