import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import UserRoutes from './components/Routes/UserRoutes.jsx';
import i18n from './components/helpers/i18next';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ModalWindow from 'components/Shared/ModalWindow/index.js';
import ScrollToTopOnRouteChange from './components/helpers/ScrollToTopOnRouteChange';
import ScrollToTopButton from './components/Shared/ScrollToTopButton';
import AdvertisingWindow from 'components/Shared/AdvertisingWindow';
import MainCookieWindow from 'components/CookiePolicy/MainCookieWindow/MainCookieWindow.jsx';
import SettingsCookieWindow from 'components/CookiePolicy/SettingsCookieWindow/SettingsCookieWindow.jsx';
import {
  getModalWindowStatus,
  getPolicyWindowVisibility,
  getSettigsWindowVisibility,
  getCookiesPolicy,
  getLanguage,
} from './redux/technical/technical-selectors.js';
import {
  setCookiesPolicy,
  setPolicyWindowVisibility,
  setLanguage,
} from './redux/technical/technical-slice.js';
import SchemaOrg from 'components/helpers/SchemaOrg';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentLanguage = useSelector(getLanguage);
  const isShowModal = useSelector(getModalWindowStatus);
  const policyWendowVisibility = useSelector(getPolicyWindowVisibility);
  const settigsWindowVisibility = useSelector(getSettigsWindowVisibility);
  const cookiesPolicy = useSelector(getCookiesPolicy);
  const [isActiveGoogleScript, setIsActiveGoogleScript] = useState(
    cookiesPolicy.googleScript
  );

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    const pathname = location.pathname;
    const language = pathname.substring(1, 3);
    if (language === 'en' || language === 'es' || language === 'ru') {
      i18n.changeLanguage(language);
    }
  }, [location]);

  useEffect(() => {
    function restoreCookieSettings() {
      const savedSettings = localStorage.getItem('ArtStudioDental.data');
      if (savedSettings) {
        const { googleScript, eclinicCitasWidget, saveSettings, language } =
          JSON.parse(savedSettings);
        const newSettings = {
          googleScript,
          eclinicCitasWidget,
          saveSettings,
        };
        dispatch(setCookiesPolicy(newSettings));
        dispatch(setPolicyWindowVisibility(false));
        dispatch(setLanguage(language));
      } else {
        return;
      }
    }
    restoreCookieSettings();
  }, [dispatch]);

  useEffect(() => {
    setIsActiveGoogleScript(cookiesPolicy.googleScript);
  }, [cookiesPolicy]);

  const googleAnalyticsScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-SHGC7JX2QE');
  `;

  return (
    <I18nextProvider i18n={i18n}>
      {isActiveGoogleScript && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-SHGC7JX2QE"
          />
          <script>{googleAnalyticsScript}</script>
        </Helmet>
      )}
      <SchemaOrg />
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Header />
        <main>
          <ScrollToTopOnRouteChange />
          <ScrollToTopButton />
          <AdvertisingWindow />
          {policyWendowVisibility && <MainCookieWindow />}
          {settigsWindowVisibility && <SettingsCookieWindow />}
          <UserRoutes />
        </main>
        <Footer />
        {isShowModal && <ModalWindow />}
      </div>
    </I18nextProvider>
  );
}

export default App;
