import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  setSettigsWindowVisibility,
  setPolicyWindowVisibility,
} from '../../../redux/technical/technical-slice';
import SwitchBtn from '../../Shared/SwitchBtn';
import Button from 'components/Shared/Button';

import s from './SettingsCookieWindow.module.scss';

const SettingsCookieWindow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [basePath, setBasePath] = useState(`/${i18n.language}`);

  useEffect(() => {
    const handleLanguageChange = () => {
      setBasePath(`/${i18n.language}`);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [basePath]);

  const saveSettings = () => {
    dispatch(setSettigsWindowVisibility(false));
    dispatch(setPolicyWindowVisibility(false));
  };

  return (
    <div className={s.settingsWindow}>
      <div className={s.settingsWindow__container}>
        <div className={s.settingsWindow__part}>
          <p className={s.settingsWindow__title}>{t('Google Analytics')}</p>
          <p className={s.settingsWindow__text}>
            {t('googleAnalyticsDescription')}
          </p>
          <SwitchBtn type="google" />
        </div>
        <div className={s.settingsWindow__part}>
          <p className={s.settingsWindow__title}>{t('Eclinic Citas Widget')}</p>
          <p className={s.settingsWindow__text}>
            {t('eclinicCitasWidgetDescription')}
          </p>
          <SwitchBtn type="eclinic" />
        </div>
        <div className={s.settingsWindow__part}>
          <p className={s.settingsWindow__title}>{t('User Settings')}</p>
          <p className={s.settingsWindow__text}>{t(`cookiePolicyStorage`)}</p>
          <SwitchBtn type="data" />
        </div>
        <div className={s.settingsWindow__btnWrapper}>
          <Button
            text={t('Save Settings')}
            btnClass="btnLight"
            handleClick={saveSettings}
          />
        </div>
        <p className={s.settingsWindow__text}>
          {t(
            `Users of our site can change their cookie settings at any time. A dedicated button for this purpose is available in the 'Cookie Usage Policy' section, providing easy access to these settings.`
          )}
        </p>
      </div>
    </div>
  );
};

export default SettingsCookieWindow;
