import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import Loader from 'components/Shared/Loader';
import i18n from 'i18next';

import './styles/styles.scss';

function getBasePath() {
  const currentLanguage = i18n.language;
  return currentLanguage === 'en'
    ? '/en'
    : currentLanguage === 'es'
    ? '/es'
    : '/ru';
}

function Root() {
  const [basePath, setBasePath] = useState(getBasePath());
  const [language, setLanguage] = useState('');
  const [languageI8, setLanguageI8] = useState('');

  const handleLanguageChange = () => {
    setLanguageI8(i18n.language);
  };

  const changeBasePath = () => {
    const newLanguage = i18n.language;
    const currentPathname = window.location.pathname;
    const currentHash = window.location.hash;
    let newPath;
    if (currentPathname.startsWith('/en')) {
      newPath = currentPathname.replace(/^\/en/, '');
    } else if (currentPathname.startsWith('/es')) {
      newPath = currentPathname.replace(/^\/es/, '');
    } else if (currentPathname.startsWith('/ru')) {
      newPath = currentPathname.replace(/^\/ru/, '');
    } else {
      newPath = currentPathname;
    }
    newPath = `/${newLanguage}${newPath}${currentHash}`;
    setBasePath(newPath);
    window.history.pushState({}, '', newPath);
    setLanguage(newLanguage);
  };

  useEffect(() => {
    changeBasePath();
    i18n.on('languageChanged', handleLanguageChange);
  }, []);

  useEffect(() => {
    if (languageI8 !== '' && languageI8 !== language && language !== '') {
      changeBasePath();
    } else {
      return;
    }
  }, [languageI8, language]);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <Routes>
            <Route path="/:lang/*" element={<App />} />
            <Route path="/" element={<Navigate to={basePath} replace />} />
          </Routes>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);

reportWebVitals();
