import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo/logo-dental.svg';

import s from './Logo.module.scss';

const Logo = ({ width, height }) => {
  const [basePath, setBasePath] = useState(`/${i18n.language}`);

  useEffect(() => {
    const handleLanguageChange = () => {
      setBasePath(`/${i18n.language}`);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [basePath]);

  return (
    <>
      <div className={s.logo}>
        <Link to={`${basePath}`} className={s.logo__link}>
          <img src={logo} alt="logo" style={{ width: width, height: height }} />
        </Link>
      </div>
    </>
  );
};

export default Logo;
