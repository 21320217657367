import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../redux/technical/technical-slice';
import { getLanguage } from '../../../redux/technical/technical-selectors';
import s from './LanguageSelect.module.scss';

const LanguageSelect = ({ changeLanguage, currentLanguage }) => {
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);
  const handleSelectChange = event => {
    changeLanguage(event.target.value);
    dispatch(setLanguage(event.target.value));
  };

  return (
    <label className={s.label}>
      Виберіть мову:
      <select
        className={s.select}
        value={language ? language : currentLanguage}
        onChange={handleSelectChange}
      >
        <option className={s.option} value="en">
          EN
        </option>
        <option className={s.option} value="es">
          ES
        </option>
        <option className={s.option} value="ru">
          RU
        </option>
      </select>
    </label>
  );
};

export default LanguageSelect;
